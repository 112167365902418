import { WindowLocation } from "@reach/router";
import { graphql } from "gatsby";
import React from "react";
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs";
import { useString } from "../i18n/GetText";
import GeneralContentLayout from "../layouts/GeneralContentLayout/GeneralContentLayout";
import PageLayout from "../layouts/PageLayout/PageLayout";
import { CommonContext, SanityCampaign } from "../model/common";
import { SanityAward } from "../model/impact";
import { GlobalMenus } from "../model/menu";
import { SiteArea } from "../utils/analytics";

export const pageQuery = graphql`
  query AwardPage($_id: String!, $navLanguage: String) {
    menus: sanityGlobalConfig(language: { eq: $navLanguage }) {
      ...MenuData
    }
    page: sanityAward(_id: { eq: $_id }) {
      _id
      _type
      title
      metaDescription
      socialTitle
      socialDescription
      socialImage {
        ...SanityImage
      }
      related {
        _id
        _type
        title
        slug {
          current
        }
        image {
          ...SanityImage
        }
      }
      metaDescription
      _rawContent(resolveReferences: { maxDepth: 5 })
    }
  }
`;

const AwardPage = ({
  pageContext,
  data: { menus, page },
  location
}: AwardProps) => {
  const { title } = page;
  const awards = useString("awards", "Awards");
  return (
    <PageLayout
      siteArea={SiteArea.IMPACT}
      metadata={{
        title,
        page,
        alternates: pageContext.alternates,
        type: "article"
      }}
      menus={menus}
      strings={pageContext.strings}
      location={location}
    >
      <GeneralContentLayout
        article={page}
        related={page.related}
        relatedTitleId="related-stories"
        breadcrumbs={
          <Breadcrumbs
            breadcrumbs={[
              { title: awards, href: ".." },
              { title, href: "" }
            ]}
          />
        }
      />
    </PageLayout>
  );
};

export default AwardPage;

interface AwardProps {
  data: {
    menus: GlobalMenus;
    page: SanityAward;
    campaigns: { nodes: SanityCampaign[] };
  };
  location: WindowLocation;
  pageContext: CommonContext;
}
